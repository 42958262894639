/* @font-face {
  font-family: "Inter";
  src: url("/src/assets/Inter-VariableFont_slnt\,wght.ttf");
} */
.green {
  color: green;
}

html {
  /* font-size: 62.5%; 16px x 62.5 = 10px = 1rem */
  box-sizing: border-box;
}
body {
  margin: 0;
  height: 100vh;
  /* font-size: 1.6rem; */
  font-family: 'Inter', sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
#root {
  height: 100%;
}

/* Layout */
.grid-container {
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 1fr 5rem;
  height: 100%;
  background-color: whitesmoke;
}
header {
  grid-area: header;
  background-color: white;
}
main {
  grid-area: main;
  /* padding: 1rem; */
}
footer {
  grid-area: footer;
  background-color: #126d0a;
  color: #ffffff;
}
/* Common */
h1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 119.5%;
  /* display: flex; */
  align-items: center;
  color: #444444;
}

h2 {
  font-size: 1.6rem;
  padding: 1rem 0;
}

a {
  text-decoration: none;
}
a:hover {
  color: #48854e;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
li {
  margin-top: 1rem;
}
input,
select,
textarea,
button {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button {
  background-color: #f8f8f8;
  cursor: pointer;
}
/* input:hover,
select:hover,
textarea:hover,
button:hover {
  border: 0.1rem #404040 solid;
} */
button.primary {
  background-color: #165f07;
}
button.block {
  width: 100%;
}
button.small {
  font-size: 1.2rem;
}
/* Header */
header {
  padding: 1rem;
  /* margin: 0.5rem; */
}
header a {
  color: black;
  padding: 1rem;
}
a.brand {
  color: black;
  font-size: 3rem;
  font-weight: bold;
}
.badge {
  background-color: #f02020;
  color: #ffffff;
  border-radius: 50%;
  padding: 0.2rem 0.7rem;
  font-size: 1.4rem;
  margin-left: 0.2rem;
}
.row {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
}
.row.center {
  justify-content: center;
}
.row.top {
  align-items: flex-start;
}
.row.start {
  justify-content: flex-start;
}
.col-1 {
  flex: 1 1 25rem;
}
.col-2 {
  flex: 2 1 50rem;
}
.col-3 {
  flex: 32 1 75rem;
}
.min-30 {
  min-width: 30rem;
}
.p-1 {
  padding: 1rem;
}

/* Login */
.login-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 140%;
  /* or 20px */
  align-items: center;
  text-align: center;
  color: #a2a2a2;
}

/* Aside */
aside {
  position: fixed;
  width: 30rem;
  height: 100%;
  background-color: #efefef;
  /* z-index: 1000; */
  transform: translateX(-30rem);
  transition: all 0.5s;
}
aside.open {
  transform: translateX(0);
}
button.open-sidebar {
  font-size: 3rem;
  padding-right: 0.5rem;
  margin-top: -1rem;
  background: none;
  color: black;
  cursor: pointer;
  border-color: transparent;
}
button.open-sidebar:hover {
  color: green;
}
aside ul {
  padding: 0;
  list-style: none;
}
aside li {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
button.close-sidebar {
  padding: 0.3rem 0.8rem;
}

/* Image */
.logo {
  max-width: 4rem;
  width: 100%;
}
.iconoMenu {
  max-width: 17px;
  background-color: transparent;
  width: 100%;
}
.nombreCategoriaMenu {
  text-transform: capitalize;
  color: #444444;
  font-family: Inter;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
}

/* Image */

img.xsmall {
  border-radius: 0.5rem;
  max-width: 4rem;
  width: 100%;
}
img.small {
  border-radius: 0.5rem;
  max-width: 14rem;
  width: 100%;
}
img.medium {
  border-radius: 0.5rem;
  max-width: 29rem;
  width: 100%;
}
img.large {
  border-radius: 0.5rem;
  width: 100%;
}
/* Category */
.categoryComponent {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 10.0872px;
  line-height: 128%;
  /* or 13px */
  text-align: center;
  color: #444444;
}

/* Card */
.card {
  border: 0.1rem #c0c0c0 solid;
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  padding: 2rem;
  margin: 1rem;
}

.price {
  font-size: 2rem;
}
/* Rating */
.rating span {
  color: #f0c040;
  margin: 0.1rem;
}
.rating span:last-child {
  color: #404040;
}
/* Alert */
.loading {
  display: block !important;
}
.success {
  color: #20a020;
}
.danger {
  color: #a02020;
}
.alert {
  padding: 1rem;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;
}
.alert-info {
  color: #2020a0;
  background-color: #e0e0ff;
}
.alert-danger {
  color: #a02020;
  background-color: #ffe0e0;
}
.alert-success {
  color: #20a020;
  background-color: #eeffe0;
}
/* Form */
.form {
  max-width: 60rem;
  margin: 0 auto;
}
.form > div {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}
.form label {
  margin: 1rem 0;
}
/* Sucursal */
.sucursalLine {
  font-size: 0.65rem;
}

/* Dropdown */
.dropdown {
  background-color: hsl(0, 8%, 65%);
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  min-width: 12rem;
  z-index: 1;
  background-color: #203040;
  margin: 0;
  margin-top: 0.4rem;
  border-radius: 0.5rem;
}
.dropdown:hover .dropdown-content {
  display: block;
}
/* Checkout Steps */
.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1;
  padding: 1rem;
  font-weight: bold;
}
.checkout-steps > div.active {
  border-top-color: green;
  color: #3fb112;
}
/* Table */
.table {
  width: 100%;
  border-collapse: collapse;
}
.table tbody tr:nth-of-type(odd) {
  background-color: #f4f4f4;
}
.table td,
.table th {
  text-align: left;
  border: 0.1rem solid #e4e4e4;
  padding: 0.5rem;
}
.table button {
  margin: 0 0.2rem;
}
/* Carousel */

/* Search */
.search {
  padding: 1rem;
  margin: 0.5rem;
}

.search button {
  border-radius: 0 0.5rem 0.5rem 0;
  border-right: none;
  margin-right: 0.5rem;
}
.search input {
  border-radius: 0.5rem 0 0 0.5rem;
  border-right: none;
  margin-left: 0.5rem;
}
.active {
  font-weight: bold;
}

/* Map */
.full-container {
  margin: -1rem;
  height: calc(100% + 2rem);
}
.map-input-box {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0.5rem auto;
  width: 25rem;
  height: 4rem;
  display: flex;
}
.map-input-box input {
  border-radius: 1rem 0 0 1rem;
  border-right: 0;
}
.map-input-box button {
  border-radius: 0 1rem 1rem 0;
  border-left: 0;
}
/* Pagination */
.pagination a {
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pagination a.active {
  font-weight: bold;
}

.support-users {
  background: #f0f0f0;
  height: 100%;
}
.support-users li {
  background-color: #f8f8f8;
}
.support-users button {
  background-color: transparent;
  border: none;
  text-align: left;
}
.support-users li {
  margin: 0;
  background-color: #f0f0f0;
  border-bottom: 0.1rem #c0c0c0 solid;
}

.support-users li:hover {
  background-color: #f0f0f0;
}
.support-users li.selected {
  background-color: #c0c0c0;
}
.support-messages {
  padding: 1rem;
}
.support-messages input {
  width: calc(100% - 9rem);
}
.support-messages ul {
  height: calc(100vh - 18rem);
  max-height: calc(100vh - 18rem);
  overflow: scroll;
}
.support-messages li {
  margin-bottom: 1rem;
}

.support-users span {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: absolute;
  margin-left: -25px;
  margin-top: 10px;
}
.support-users .offline {
  background-color: #808080;
}
.support-users .online {
  background-color: #20a020;
}
.support-users .unread {
  background-color: #f02020;
}

/* cart */

.cart-title {
  background: #ffffff;
  padding: 20px;
}
.cart-items {
  background: #f4f4f4;
  border-radius: 0px 0px 12px 12px;
}
.card-cart {
  padding: 20px;
  margin: 15px;
  background: #ffffff;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.box-precio {
  background: #ffffff;
  padding: 20px;
  margin: 5px;
}
.button-fullWidth {
  height: 56px;
  border-radius: 6px;
}
/* profile -*/
.paddingAvatar {
  padding-top: 30px;
}
.cardDireccion {
  padding: 15px;
  min-width: 330px;
}
.card-user {
  max-width: 330px;
  padding: 5px;
  margin: 10px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

